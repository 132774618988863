import { z } from "zod";

const TimeRangeSchema = z.object({
  start: z.string().datetime().optional(),
  end: z.string().datetime().optional(),
});

const ShiftFeedbackQuestionSchema = z.object({
  description: z.string(),
  response: z.string(),
  responseType: z.string().optional(),
});

const ShiftFeedbackTimecardSchema = z.object({
  url: z.string(),
  filename: z.string(),
  fileStorageFileKey: z.string(),
});

const ShiftFeedbackResponseAttributesSchema = z.object({
  shiftId: z.string(),
  signedBy: z.string(),
  timecard: ShiftFeedbackTimecardSchema.optional(),
  breakAuthorizationReason: z.string().optional(),
  clockInOut: TimeRangeSchema.optional(),
  lunchInOut: TimeRangeSchema.optional(),
  questions: z.array(ShiftFeedbackQuestionSchema),
});

export const ShiftFeedbackResponseSchema = z.object({
  type: z.literal("shiftFeedback"),
  id: z.string(),
  attributes: ShiftFeedbackResponseAttributesSchema,
});

export const CreateShiftFeedbackResponseSchema = z.object({
  data: ShiftFeedbackResponseSchema,
});

export const CreateShiftFeedbackSchema = z.object({
  signedBy: z.string(),
  timecard: ShiftFeedbackTimecardSchema.optional(),
  breakAuthorizationReason: z.string().optional(),
  clockInOut: TimeRangeSchema.optional(),
  lunchInOut: TimeRangeSchema.optional(),
  shiftId: z.string(),
  question: z.string(),
  response: z.string(),
  type: z.string().optional(),
  workerId: z.string(),
});

export const GetShiftFeedbacksResponseSchema = z.object({
  data: z.array(ShiftFeedbackResponseSchema),
});

// Type inference
export type ShiftFeedbackResponse = z.infer<typeof ShiftFeedbackResponseSchema>;
export type CreateShiftFeedbackRequest = z.infer<typeof CreateShiftFeedbackSchema>;
