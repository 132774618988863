import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  GetShiftFeedbacksResponseSchema,
  type ShiftFeedbackResponse,
} from "@src/appV2/ShiftFeedback/schema";
import { useQuery, type UseQueryOptions } from "@tanstack/react-query";
import { isEmpty } from "lodash";

export function useGetShiftFeedback(
  params: { workerId: string; shiftId: string },
  options: UseQueryOptions<ShiftFeedbackResponse[], unknown, ShiftFeedbackResponse[]> = {}
) {
  const { workerId, shiftId } = params;
  return useQuery({
    queryKey: ["shiftFeedback", workerId, shiftId],
    queryFn: async () => {
      const response = await get({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${workerId}/shift-feedbacks?filter[shiftId]=${shiftId}`,
        responseSchema: GetShiftFeedbacksResponseSchema,
      });

      return response.data.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_SHIFT_FEEDBACK_FAILURE,
    },
    retry: 0,
    staleTime: 300_000,
    refetchInterval: 600_000,
    enabled: !isEmpty(workerId) && !isEmpty(shiftId),
    ...options,
  });
}
