import { isDefined } from "@clipboard-health/util-ts";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

import { type NoteAcknowledgementAction } from "../types";
import {
  getAcknowledgementsRequestSchema,
  type GetAcknowledgementsResponse,
  getAcknowledgementsResponseSchema,
} from "./types";

export function getPolicyAcknowledgementUrl(noteId?: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/facility-notes/${
    noteId ?? ""
  }/acknowledgements`;
}

export interface UseGetPolicyAcknowledgementProps {
  noteId?: string;
  policyAcknowledgementAction: NoteAcknowledgementAction;
}

export function useGetPolicyAcknowledgement(
  props: UseGetPolicyAcknowledgementProps,
  options: UseGetQueryOptions<GetAcknowledgementsResponse> = {}
): UseQueryResult<GetAcknowledgementsResponse> {
  const { noteId, policyAcknowledgementAction } = props;

  return useGetQuery({
    url: getPolicyAcknowledgementUrl(noteId),
    queryParams: policyAcknowledgementAction
      ? { filter: { action: policyAcknowledgementAction } }
      : undefined,
    requestSchema: getAcknowledgementsRequestSchema,
    responseSchema: getAcknowledgementsResponseSchema,
    meta: { logErrorMessage: APP_V2_APP_EVENTS.GET_MANDATORY_BREAK_POLICY_ACKNOWLEDGEMENT_FAILURE },
    ...options,
    enabled: options.enabled && isDefined(noteId),
  });
}
